import React, { Component } from 'react'
import Layout from '../../layout'
import { getTranslateFunction, scrollToTarget } from '../../../utilities'
import ContactForm from '../../contactForm/ContactForm'
import SideMenuServices from '../../sideMenu/side-menu-services'
import PageTitle from '../../pageTitle'
import Reveal from '../../reveal'
import { convertNonBreakingSpace } from '../../../utilities'
import * as styles from "./Styles.module.scss"
import HotLink from '../../hotLink/HotLink'
import HoverBtn from '../../HoverBtn'
import BlockSwitcher from '../../blockSwitcher/BlockSwitcher'
import Footer from '../../../layouts/footer'

class ServicesServiceTemplate extends Component {

  scrollBotton(id = 'content') {
    scrollToTarget(document.getElementById(id), 600)
  }

  render() {
    const { data, lang } = this.props
    const content = data.service.childMarkdownRemark.frontmatter

    
    const t = getTranslateFunction(lang)
    return (
      <Layout lang={lang}>
        <section className={`${styles.header}`}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7 offset-xl-3 ${styles.headerLeft} fix-nav-offset`}>
                <div className='row subpage-title align-items-center mb-0'>
                  <div className="col-12 pr-0 ">
                    
                    <div className={`fixWidth`}>
                      <PageTitle
                        className="maxWidthIndicator"
                        seoTitle={content.seo_title}
                        seoDescription={content.seo_description}
                        title={content.title}
                        description={content.description}
                        url="https://narative.sk/sluzby"
                      />
                        <p dangerouslySetInnerHTML={{ __html: convertNonBreakingSpace(content.subtitle) }}></p>
                        <p className="description-smaller pr-0" dangerouslySetInnerHTML={{ __html: content.description }}></p>
                    </div>
                  </div>
                  <div className={`col-12 ${styles.headerButton}`}>
                    <HoverBtn size={1.25} handleClick={() => this.scrollBotton('content')}>
                      <div className={styles.btnInner}>
                        <span>Zistite viac</span>
                        <img src="/images/svg/long_down.svg" alt="arrow" className='arrow' />
                      </div>
                    </HoverBtn>
                  </div>
                </div>
              </div>
              <div className={`col-lg-7 offset-xl-3 fix-nav-offset ${styles.headList}`}>
                {content.header_list.map(item => (
                  <div className={`row`}>
                    <div className='col-12 d-flex align-items-center mb-3'>
                      <img className={styles.icon} alt="icon" src={item.icon.publicURL} />
                      <h4 className={`${styles.title} ml-3`}>{ item.title }</h4>
                    </div>
                    <div className={`col-12`}>
                      <p className={`${styles.smallDescription}`}>
                        { item.description }
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className={`col-lg-7 offset-xl-3 fix-nav-offset ${styles.headerButtonMd}`}>
                <HoverBtn size={1.25} handleClick={() => this.scrollBotton('content')}>
                  <div className={styles.btnInner}>
                    <span>Zistite viac</span>
                    <img src="/images/svg/long_down.svg" alt="arrow" className='arrow' />
                  </div>
                </HoverBtn>
              </div>
            </div>
          </div>
        </section>
        <Reveal>
            <div>
            <div className={`d-lg-flex`} >
                <div className={`d-none d-lg-block col-lg-3 side-menu-height-reference ${styles.sideMenuFix}`} id="left">
                <SideMenuServices lang={lang} isGhost={false} />
                </div>
            
                <div className={`col-lg-9 ${styles.cont}`} >
                <div className='container p-md-0'>
                    <section id='content' className={styles.fixContentScroll}>
                    <div className='fixWidth mb-5'>
                        <h3 className={`${styles.h3} maxWidthIndicator`}>{content.content.title}</h3>
                        <p className='description-smaller'>{content.content.description}</p>
                    </div>
                    <div>
                    <div className='row justify-content-center justify-content-xl-between'>
                        {content.content.list.map(item => (
                        <div className={`col-sm-6 col-xl-4 ${styles.list}`}>
                            <img className={`${styles.icon} mb-4`} alt="icon" src={item.icon.publicURL} />
                            <h4 className={`${styles.title} mb-3`}>{item.title}</h4>
                            <p className={`${styles.smallDescription}`}>
                                { item.description }
                            </p>
                        </div>
                        ))}
                    </div>
                    </div>
                </section>
                </div>
                
                </div>
                </div>
            </div>
        </Reveal>  
        <Reveal>
          <section className={styles.section}>
            <HotLink />
          </section>
        </Reveal>
        <Reveal>
          <section className={`${styles.section}`}>
            <div className='container mb-5 pb-5'>
              <div className='row'>
                <div className='col-lg-6 pr-2 pr-md-5'>
                  <div className={styles.blockTitle}>
                    <h3 className={styles.h3}>{content.block_swithcer.title}</h3>
                    <p className={`${styles.description} mt-3`}>{content.block_swithcer.description}</p>
                    <HoverBtn className={styles.emailBtn} handleClick={() => this.scrollBotton('contactForm')}>
                      <div className={`d-flex align-items-center`}>
                        <span className='mr-2'>Kontaktujte nás</span>
                      </div>
                    </HoverBtn>
                  </div>
                </div>
                <div className={`col-lg-6`}>
                  <BlockSwitcher items={content.block_swithcer.list}/>
                </div>
              </div>
            </div>
          </section>
        </Reveal>

         
        <Reveal otherClassName='contact-form-bottom-space'>
          <ContactForm />
        </Reveal>
           
        <Reveal> 
            <footer>
                <Footer lang={lang} />
            </footer>
        </Reveal>
      </Layout>
    )
  }
}

export default ServicesServiceTemplate
