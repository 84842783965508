import React, { Component } from 'react'

//import { TranslatedLink } from '../TranslatedLink';
import * as styles from "./Styles.module.scss"
//import HoverBtn from '../HoverBtn';

class HotLink extends Component {


  render() {
    return (
      <section className={styles.hotLink}>
        <div className="d-lg-flex">
          <div className={"col-lg-6"}>
            <div className="d-lg-flex">
              <div className={`col-12 ml-auto ${styles.leftSide}`}>
                <div>
                  <h3>Technická podpora</h3>
                  <h2>Riešenie problémov 24/7</h2>
                  <p>Vyskytol sa na vašom webe problém? Nefunguje niečo ako má, prípadne potrebujete rýchlo doprogramovať novú funkciu? Nemusíte zbytočne dlho čakať, sme vám k dispozícii vrátane víkendov.</p>
                 {/*  <HoverBtn color="silver" textColor="black" className="email">
                    <TranslatedLink to="/kontakt">
                      <div className={`${styles.send} d-flex align-items-center`}>
                        <span className='mr-2'>Kontaktujte nás</span>
                        <img alt="mail" src='/images/mail.svg' />
                      </div>
                    </TranslatedLink>
                  </HoverBtn> */}
                </div>
                
              </div>
            </div>
            <div className={styles.bg}>
              <div/>
              <img alt="mail" src='/images/hot-link.svg' />
            </div>
            
          </div>
          <div className={`col-lg-6 ${styles.rightSide}`}>
            <div className="d-md-flex">
              <div className="col-12">
                <h3>Doporučte naše služby a získajte províziu + 3 mesiace pravidelného servisu zdarma.</h3>
                <p>Okrem tejto ponuky môžete využiť aj ďalšiu a to, keď máte viac webov, ktoré si prajete servisovať. Za každý ďalší web u nás sa vám znižuje cena servisov všetkých webov.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HotLink

