// extracted by mini-css-extract-plugin
export var blockTitle = "Styles-module--blockTitle--LHj2G";
export var btnInner = "Styles-module--btnInner--UByDt";
export var cont = "Styles-module--cont--g6VzC";
export var description = "Styles-module--description--1ccy3";
export var emailBtn = "Styles-module--emailBtn--mO6Cl";
export var fixContentScroll = "Styles-module--fixContentScroll--ogR3k";
export var h3 = "Styles-module--h3--kYB8t";
export var headList = "Styles-module--headList--pjOkL";
export var header = "Styles-module--header--PQOIt";
export var headerButton = "Styles-module--headerButton--nqv3G";
export var headerButtonMd = "Styles-module--headerButtonMd--a+arg";
export var headerLeft = "Styles-module--headerLeft--jZ-Gq";
export var icon = "Styles-module--icon--WObT9";
export var list = "Styles-module--list--SiSWN";
export var section = "Styles-module--section--mTKz-";
export var sideMenuFix = "Styles-module--sideMenuFix--yZYuE";
export var smallDescription = "Styles-module--smallDescription--O5DeW";
export var title = "Styles-module--title--QJYc5";