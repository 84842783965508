import React from 'react'
import { graphql } from 'gatsby'
import ServicesServiceTemplate from '../../components/pages/servicesServiceTemplate/ServicesServiceTemplate'

const OurServicesSk = ({data}) => (
  <ServicesServiceTemplate data={data} lang={'sk'}/>
)
export const query = graphql`{
    service: file(relativePath: {eq: "pages/sk/services/service.md"}) {
        childMarkdownRemark {
            frontmatter {
              seo_title
              seo_description
              title
              subtitle
              description
              header_list {
                title
                description
                icon {
                  publicURL
                }
              }
              content {
                title
                description
                list {
                  title
                  description
                  icon {
                    publicURL
                  }
                }
              }
              block_swithcer {
                title
                description
                list {
                  title
                  description
                }
              }
            }
        }
    }
}
`

export default OurServicesSk
